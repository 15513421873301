<template>
  <div>
    <Loader :visible="isLoading" />
    <div v-if="!isLoading">
      <div class="tway-header-1">Datos personales</div>

      <v-container class="mb-4 pa-0" fluid>
        <v-row align="stretch">
          <v-col :cols="desktop ? '9' : '12'">
            <v-card class="pa-5 h-100">
              <v-row align="center" class="ma-0">
                <v-col cols="1" xs="1" sm="1" md="1" lg="1" xl="1">
                  <v-img
                    :src="require('@/assets/img/avatar.svg')"
                    max-width="100"
                    min-width="50"
                    class="rounded"
                    contain
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                  <div class="title-profile">Nombre</div>
                  <div class="text-profile">
                    {{ user.firstName }}
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                  <div class="title-profile">Apellido</div>
                  <div class="text-profile">
                    {{ user.lastName }}
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <div class="title-profile">Correo</div>
                  <div class="text-profile">
                    {{ user.username }}
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-btn
                    style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                    outlined
                    color="deep-purple accent-4"
                    class="roboto"
                    rounded
                    :to="{ name: 'change-password' }"
                  >
                    MODIFICAR CONTRASEÑA
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col :cols="desktop ? '3' : '0'" class="desktop">
            <v-card class="py-3 px-3 h-100">
              <v-row align="center">
                <v-col cols="5">
                  <v-img
                    v-if="company.type == 'SPECIALIST'"
                    :src="require('@/assets/img/perfilamiento-e.svg')"
                    class="rounded"
                    max-width="100%"
                    contain
                  />
                  <v-img
                    v-if="company.type == 'CLIENT'"
                    :src="require('@/assets/img/perfilamiento.svg')"
                    class="rounded"
                    max-width="100%"
                    contain
                  />
                </v-col>
                <v-col cols="7">
                  <div>Perfil</div>
                  <strong v-if="company.type == 'SPECIALIST'" class="text-h6 text--tway-apple-green"
                    >Especialista</strong
                  >
                  <strong v-if="company.type == 'CLIENT'" class="text-h5 text--tway-apple-green">Cliente</strong>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div v-if="company.type === 'SPECIALIST'" class="tway-header-1">Datos Especialista</div>
      <div v-if="company.type === 'CLIENT'" class="tway-header-1">Datos Cliente</div>

      <v-row>
        <v-col class="pa-0" :cols="desktop ? '9' : '12'">
          <v-container class="ma-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0 pa-0">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100" v-if="company.name">
                  <div class="title-profile">Nombre Empresa</div>
                  <div class="text-profile" v-if="company.name">
                    {{ company.name }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.name">
                  <div class="title-profile">Nombre Empresa</div>
                  <div class="text-error">Nombre no Registrado</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100">
                  <div class="title-profile">Identificador Tributario</div>
                  <div class="text-profile">
                    {{ company.tributaryId }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100">
                  <div class="title-profile">País</div>
                  <div class="text-profile">
                    {{ countryValue }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="company.type == 'CLIENT'" class="mb-0 ml-0 mr-0 mt-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0 h-100">
              <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="full-height">
                <v-row class="ma-0 h-100">
                  <v-container fluid class="ma-0 pb-4 pa-0">
                    <v-card class="py-10 px-5 h-100" v-if="company.web">
                      <div class="title-profile">Sitio Web</div>
                      <div class="text-profile">
                        <a class="tway-violet--text" target="_blank" :href="'http://' + company.web">{{
                          company.web
                        }}</a>
                      </div>
                    </v-card>
                    <v-card class="py-10 px-5 h-100" v-if="!company.web">
                      <div class="title-profile">Sitio Web</div>
                      <div class="text-error">Sitio Web no registrado</div>
                    </v-card>
                  </v-container>
                  <v-container fluid class="ma-0 pa-0">
                    <v-card class="py-10 px-5 h-100" v-if="company.linkedIn">
                      <div class="title-profile">Perfil Linkedin</div>
                      <div class="text-profile">
                        <a class="tway-violet--text" target="_blank" :href="'http://' + company.linkedIn">{{
                          company.linkedIn
                        }}</a>
                      </div>
                    </v-card>
                    <v-card class="py-10 px-5" v-if="!company.linkedIn">
                      <div class="title-profile">Perfil Linkedin</div>
                      <div class="text-error">Perfil en LinkedIn no registrado</div>
                    </v-card>
                  </v-container>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <v-card class="pa-3 h-100" v-if="company.contributionValue">
                  <div class="title-profile">Acerca de la Empresa</div>
                  <v-textarea
                    class="text-profile"
                    v-model="company.contributionValue"
                    rows="7"
                    no-resize
                    rounded
                    hide-details
                    disabled
                  />
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.contributionValue">
                  <div class="title-profile">Acerca de la empresa</div>
                  <v-textarea
                    class="text-profile"
                    placeholder="Descripcion de Empresa no Registrada"
                    rows="7"
                    no-resize
                    rounded
                    hide-details
                    disabled
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="company.type == 'SPECIALIST'" class="ma-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-5 h-100">
                  <div class="title-profile mb-2">Impacto en Transformación Digital</div>
                  <v-tooltip
                    class="d-inline mr-2"
                    v-for="(dimension, index) in company.dimensions.slice(0, 6)"
                    :key="index"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" color="tway-gray mb-2">
                        <span class="roboto">{{ dimension.name.substring(0, 10) }}</span>
                      </v-chip>
                    </template>
                    <span>{{ dimension.name }}</span>
                  </v-tooltip>
                  <v-row justify="end">
                    <v-dialog v-model="modalDimensions" scrollable max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="deep-purple accent-4"
                          class="button-text roboto"
                          text
                          v-bind="attrs"
                          v-on="on"
                          style="position: absolute; bottom: 5px; right: 10px"
                        >
                          VER MÁS
                        </v-btn>
                      </template>
                      <v-card>
                        <div class="title-profile ml-5 mt-2 pa-2">Experiencia en</div>
                        <div class="title-profile ml-5 mb-2 pa-2">
                          {{ company.dimensions.length }}
                          <span v-if="company.dimensions.length > 1">Dimensiones Seleccionadas</span
                          ><span v-else>Dimension Seleccionada</span>
                        </div>
                        <v-card-text>
                          <v-tooltip
                            class="d-inline mr-2"
                            v-for="(dimension, index) in company.dimensions"
                            :key="index"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" color="tway-gray mb-2">
                                <span class="roboto">{{ dimension.name.substring(0, 25) }}</span>
                              </v-chip>
                            </template>
                            <span>{{ dimension.name }}</span>
                          </v-tooltip>
                        </v-card-text>
                        <v-card-actions>
                          <v-col class="text-right">
                            <v-btn
                              color="deep-purple accent-4"
                              class="button-text roboto"
                              text
                              @click="modalDimensions = false"
                            >
                              VOLVER
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-5 h-100">
                  <div class="title-profile mb-2">Servicios que ofrecen</div>
                  <v-tooltip
                    class="d-inline mr-2"
                    v-for="(service, index) in company.services.slice(0, 6)"
                    :key="index"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" color="tway-gray mb-2">
                        <span class="roboto">{{ service.name.substring(0, 10) }}</span>
                      </v-chip>
                    </template>
                    <span>{{ service.name }}</span>
                  </v-tooltip>
                  <v-row justify="end">
                    <v-dialog v-model="modalServices" scrollable max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="deep-purple accent-4"
                          class="button-text roboto"
                          text
                          v-bind="attrs"
                          v-on="on"
                          style="position: absolute; bottom: 5px; right: 10px"
                        >
                          VER MÁS
                        </v-btn>
                      </template>
                      <v-card>
                        <div class="title-profile ml-5 mt-2 pa-2">Servicios que ofrece</div>
                        <div class="title-profile ml-5 mb-2 pa-2">
                          {{ company.services.length }}
                          <span v-if="company.services.length > 1">Servicios Seleccionados</span
                          ><span v-else>Servicio Seleccionado</span>
                        </div>
                        <v-card-text>
                          <v-tooltip
                            class="d-inline mr-2"
                            v-for="(service, index) in company.services"
                            :key="index"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" color="tway-gray mb-2">
                                <span class="roboto">{{ service.name.substring(0, 25) }}</span>
                              </v-chip>
                            </template>
                            <span>{{ service.name }}</span>
                          </v-tooltip>
                        </v-card-text>
                        <v-card-actions>
                          <v-col class="text-right">
                            <v-btn
                              color="deep-purple accent-4"
                              class="button-text roboto"
                              text
                              @click="modalServices = false"
                            >
                              VOLVER
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-5 h-100">
                  <div class="title-profile mb-2">Experiencia en</div>
                  <v-tooltip
                    class="d-inline mr-2"
                    v-for="(sector, index) in company.industrialSectors.slice(0, 6)"
                    :key="index"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" color="tway-gray mb-2">
                        <span class="roboto">{{ sector.name.substring(0, 10) }}</span>
                      </v-chip>
                    </template>
                    <span>{{ sector.name }}</span>
                  </v-tooltip>
                  <v-row justify="end">
                    <v-dialog v-model="modalSectors" scrollable max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="deep-purple accent-4"
                          class="button-text roboto"
                          text
                          v-bind="attrs"
                          v-on="on"
                          style="position: absolute; bottom: 5px; right: 10px"
                        >
                          VER MÁS
                        </v-btn>
                      </template>
                      <v-card>
                        <div class="title-profile ml-5 mt-2 pa-2">Experiencia en</div>
                        <div class="title-profile ml-5 mb-2 pa-2">
                          {{ company.industrialSectors.length }}
                          <span v-if="company.industrialSectors.length > 1">Sectores Seleccionados</span
                          ><span v-else>Sector Seleccionado</span>
                        </div>

                        <v-card-text>
                          <v-tooltip
                            class="d-inline mr-2"
                            v-for="(sector, index) in company.industrialSectors"
                            :key="index"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip v-on="on" color="tway-gray mb-2">
                                <span class="roboto">{{ sector.name.substring(0, 25) }}</span>
                              </v-chip>
                            </template>
                            <span>{{ sector.name }}</span>
                          </v-tooltip>
                        </v-card-text>
                        <v-card-actions>
                          <v-col class="text-right">
                            <v-btn
                              color="deep-purple accent-4"
                              class="button-text roboto text-right"
                              text
                              @click="modalSectors = false"
                            >
                              VOLVER
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-3 h-100">
                  <div class="title-profile">Acerca de la Empresa</div>
                  <v-textarea
                    class="text-profile"
                    v-model="company.contributionValue"
                    rows="7"
                    no-resize
                    rounded
                    hide-details
                    disabled
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="company.type === 'SPECIALIST'" class="mb-0 ml-0 mr-0 mt-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0">
              <v-col v-if="company.type != 'SPECIALIST'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100" v-if="company.industrialSector">
                  <div class="title-profile">Negocio Principal</div>
                  <div class="text-profile">
                    {{ company.industrialSector.name }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.industrialSector">
                  <div class="title-profile">Negocio Principal</div>
                  <div class="text-error">Sector No Registrado</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-5 h-100" v-if="company.trajectory">
                  <div class="title-profile">Inicio Actividades</div>
                  <div class="text-profile">
                    {{ company.trajectory }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.trajectory">
                  <div class="title-profile">Inicio Actividades</div>
                  <div class="text-error">Inicio de Actividades no Registrado</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="pa-5 h-100" v-if="company.size">
                  <div class="title-profile">Tamaño Empresa</div>
                  <div class="text-profile">
                    {{ company.size.name }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.size">
                  <div class="title-profile">Inicio Actividades</div>
                  <div class="text-error">Tamaño de Empresa no Registrado</div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="company.type === 'SPECIALIST'" class="mb-0 ml-0 mr-0 mt-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0 h-100">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="full-height">
                <v-container fluid class="ma-0 pb-4 pa-0">
                  <v-card class="py-10 px-5 h-100" v-if="company.web">
                    <div class="title-profile">Sitio Web</div>
                    <div class="text-profile">
                      <a class="tway-violet--text" target="_blank" :href="'http://' + company.web">{{ company.web }}</a>
                    </div>
                  </v-card>
                  <v-card class="py-10 px-5 h-100" v-if="!company.web">
                    <div class="title-profile">Sitio Web</div>
                    <div class="text-error">Sitio Web no registrado</div>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="full-height">
                <v-container fluid class="ma-0 pa-0">
                  <v-card class="py-10 px-5 h-100" v-if="company.linkedIn">
                    <div class="title-profile">Perfil Linkedin</div>
                    <div class="text-profile">
                      <a class="tway-violet--text" target="_blank" :href="'http://' + company.linkedIn">{{
                        company.linkedIn
                      }}</a>
                    </div>
                  </v-card>
                  <v-card class="py-10 px-5" v-if="!company.linkedIn">
                    <div class="title-profile">Perfil Linkedin</div>
                    <div class="text-error">Perfil en LinkedIn no registrado</div>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
            <v-row class="ma-0" justify="end">
              <v-col :class="desktop ? 'text-right' : 'text-center'">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="white--text roboto"
                  :to="{ name: 'complete-profile' }"
                >
                  Modificar Perfil
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="company.type != 'SPECIALIST'" class="mb-0 ml-0 mr-0 mt-0 pa-0" fluid>
            <v-row align="stretch" class="ma-0">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100" v-if="company.industrialSector">
                  <div class="title-profile">Negocio Principal</div>
                  <div class="text-profile">
                    {{ company.industrialSector.name }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.industrialSector">
                  <div class="title-profile">Negocio Principal</div>
                  <div class="text-error">Sector no Registrado</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100" v-if="company.trajectory">
                  <div class="title-profile">Inicio Actividades</div>
                  <div class="text-profile">
                    {{ company.trajectory }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.trajectory">
                  <div class="title-profile">Inicio Actividades</div>
                  <div class="text-error">Inicio Actividades no Registrado</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-card class="pa-5 h-100" v-if="company.size">
                  <div class="title-profile">Tamaño Empresa</div>
                  <div class="text-profile">
                    {{ company.size.name }}
                  </div>
                </v-card>
                <v-card class="pa-5 h-100" v-if="!company.size">
                  <div class="title-profile">Tamaño Empresa</div>
                  <div class="text-error">Tamaño Empresa no Registrado</div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="ma-0" justify="end">
              <v-col :class="desktop ? 'text-right' : 'text-center'">
                <v-btn
                  style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                  rounded
                  color="tway-violet"
                  class="white--text roboto"
                  :to="{ name: 'complete-profile' }"
                >
                  Modificar Perfil
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col :cols="desktop ? '3' : '0'" class="desktop">
          <v-card class="pa-5">
            <div class="subtitle-1">¿Qué puede hacer mi empresa en Tway?</div>
            <v-row
              class="ma-0"
              v-for="(link, index) in company.type === 'SPECIALIST' ? links.specialist : links.client"
              :key="index"
            >
              <v-col class="ma-0">
                <span class="text-subtitle-2 roboto">{{ link }}</span>
              </v-col>
            </v-row>
            <v-col class="text-center">
              <v-btn outlined color="tway-apple-green" class="button-text roboto" rounded :to="{ name: 'plans' }">
                VER PLANES
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-container />
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import Loader from "@/components/Loader";
import currentUserService from "@/services/currentUserService";
import companyService from "@/services/companyService";
import CountryCodes from "@/utils/CountryCodes.json";
import industrialSectorJSON from "@/utils/industrialSector.json";
import sizeJSON from "@/utils/size.json";
import servicesJSON from "@/utils/services.json";
export default {
  name: "Profile",

  components: {
    Loader,
  },

  data: () => ({
    isLoading: true,
    availableCountries: CountryCodes,
    user: {
      firstName: "",
      lastName: "",
      username: "",
    },
    company: {
      industrialSector: {
        name: "",
      },
      industrialSectors: [],
      contributionValue: "",
      trajectory: "",
      size: {
        name: "",
      },
      countryCode: "",
      web: "",
      linkedIn: "",
      services: [],
    },
    availableSizes: [],
    availableIndustrialSectors: [],
    availableServices: [],
    links: {
      client: [
        "• Conocer el Índice de Madurez en Transformación Digital",
        "• Acceder a Especialistas, rankeados y validados que ofrecen soluciones a la medida para mejorar la competitividad de tu negocio.",
        "• Medir el impacto de la Transformación Digital en tus indicadores de negocio.",
        "• Contar con acompañamiento del primer PMO Digital en Transformación Estratégica y mucho más.",
      ],
      specialist: [
        "• Promocionar productos y servicios.",
        "• Conectar con socios estratégicos.",
        "• Concretar nuevos negocios en el Marketplace.",
        "• Acceder a licitaciones.",
        "• Optimizar procesos de ventas.",
      ],
    },
    modalSectors: false,
    modalServices: false,
    modalDimensions: false,
    countryValue: "",
  }),

  async created() {
    Promise.all([
      this.getProfile(),
      this.getCurrentUserData(),
      this.getSizes(),
      this.getIndustrialSectors(),
      this.getServices(),
    ]).then(() => (this.isLoading = false));
  },
  methods: {
    getServices() {
      this.availableServices = servicesJSON;
    },
    getIndustrialSectors() {
      this.availableIndustrialSectors = industrialSectorJSON;
    },
    getSizes() {
      this.availableSizes = sizeJSON;
    },
    getCurrentUserData() {
      currentUserService
        .currentUserData()
        .then((userData) => (this.user = userData))
        .catch((err) => this.$log.error(err));
    },

    getProfile() {
      companyService
        .getCompanyProfile()
        .then((company) => (this.company = company))
        .catch((err) => this.$log.error(err))
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    company() {
      let data = this.availableCountries.find((element) => element.alpha2 == this.company.countryCode);
      this.countryValue = data != undefined ? data.name : "";
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
